.work_schedule_input_container {
  display: flex;
  align-items: center;
  min-width: 25%;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}

.work_schedule_td_container {
  width: 25%;
}
