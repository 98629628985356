.rbt-token {
  background-color: hsl(0, 0%, 90%);
  color: hsl(0, 0%, 20%);
  font-size: 85%;
  padding: 5px 26px 5px 8px;

  .rbt-token-remove-button {
    padding: 4px 7px;
    font-size: 1rem;
    font-weight: bold;

    &:hover {
      background-color: #ffbdad;
      color: #de350b;
    }
  }
}

.rbt-input-multi.focus {
  border-color: $green;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
