.cms-content {
  //width: fit-content;
  min-width: 100%;
}

.cms-content__section {
  position: relative;
  background-color: $gray-100;
  padding: $spacer;
  border: 1px solid rgba(0, 0, 0, 0.05);

  tr,
  td {
    border: 1px solid $gray-800;
    padding: 2px 4px;
  }
}
