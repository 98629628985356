@import '../global/bootstrap-colors-overrides.scss';
// Your variable overrides
$sidebar-bg-color: $gray-300;
$sidebar-color: $gray-700;
$sidebar-width: 270px;
$sidebar-collapsed-width: 80px;
$highlight-color: $gray-700;
$submenu-bg-color: $gray-300;
$submenu-bg-color-collapsed: $gray-300;
$icon-bg-color: $gray-400;
$icon-size: 25px;

@import '~react-pro-sidebar/dist/scss/styles.scss';
@import '~react-slidedown/lib/slidedown.css';

$topbar-calculated-height: 61px;
$topbar-calculated-height-new-version: 101px;
$sidebar-width-xl: 250px;
$sidebar-width-lg: 220px;
$sidebar-width-md: 90px;

.main-content {
  @include media-breakpoint-up(md) {
    padding-left: $sidebar-width-md;
  }
  @include media-breakpoint-up(lg) {
    padding-left: $sidebar-width-lg;
  }
  @include media-breakpoint-up(xl) {
    padding-left: $sidebar-width-xl;
  }
  padding-top: $topbar-calculated-height;
  @media print {
    padding-left: 0 !important;
  }
}

.pro-sidebar {
  &.mediaaccounts-sidebar {
    position: fixed;
    margin-top: $topbar-calculated-height;
    z-index: 5;
    @include media-breakpoint-down(sm) {
      height: 0;
      width: 100%;
      &.show {
        display: block;
        height: 100%;
      }
    }

    height: calc(100vh - 61px);

    .pro-arrow-wrapper {
      //display: none;
    }

    @include media-breakpoint-up(md) {
      width: $sidebar-width-md;
      min-width: $sidebar-width-md;
      .pro-arrow-wrapper {
        display: block;
      }
      &:hover {
        width: $sidebar-width-lg;
        min-width: $sidebar-width-lg;
      }
    }
    @include media-breakpoint-up(lg) {
      width: $sidebar-width-lg;
      min-width: $sidebar-width-lg;
      &:hover {
        width: $sidebar-width-lg;
        min-width: $sidebar-width-lg;
      }
      .pro-icon-wrapper {
        display: none;
      }
    }
    @include media-breakpoint-up(xl) {
      width: $sidebar-width-xl;
      min-width: $sidebar-width-xl;
      &:hover {
        width: $sidebar-width-xl;
        min-width: $sidebar-width-xl;
      }
      .pro-icon-wrapper {
        display: inline-block;
      }
    }

    .pro-menu-item {
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      font-size: 15px;
      @include media-breakpoint-up(md) {
        font-size: 13px;
      }
      @include media-breakpoint-up(xl) {
        font-size: 15px;
      }

      &.pro-sub-menu .pro-menu-item {
        border-bottom: 0 none transparent;
      }

      .suffix-wrapper {
        margin-left: 4px;
      }
    }

    .pro-inner-list-item ul {
      padding: 5px 0 !important;

      .pro-inner-item {
        padding: 5px 10px 5px 5px !important;
        font-size: 0.8rem;

        &:before {
          height: 1px !important;
          box-shadow: none !important;
          background-color: $medi-red !important;
          border: 0 none transparent !important;
          width: 10px !important;
        }
      }
    }

    .pro-icon {
      font-size: 16px !important;
    }
  }
}

.app-new-version {
  .main-content {
    padding-top: $topbar-calculated-height-new-version;
  }

  .pro-sidebar {
    &.mediaaccounts-sidebar {
      margin-top: $topbar-calculated-height-new-version;
    }
  }
}

// Header toggle button
