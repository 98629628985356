.dashboard-statistics {
  font-size: 4rem;
  font-weight: 500;
  text-align: center;
  line-height: 1;
}

.dashboard-statistics-wrapper {
  @include media-breakpoint-up(md) {
    min-height: 110px;
  }
}

.dashboard-chart-wrapper {
  --color: #20c997; // SCSS variables not working with css variables... ?
  display: grid;
  align-items: center;
  justify-items: center;
  grid-template-rows: 300px 10px;
  grid-template-areas:
    'chart'
    //"primary-axis"
;
  margin-bottom: 1rem;
  overflow-x: auto;
  overflow-y: hidden;

  table {
    grid-area: chart;

    tbody {
      tr {
        padding-top: 1.5rem;
        min-width: 44px;
      }
      td {
        transform-origin: bottom;
        animation: revealing-bars 4s linear;
      }
    }

    span.data {
      position: relative;
      top: -20px;
      font-weight: 600;
    }
  }

  .primary-axis {
    grid-area: primary-axis;
  }
}

.dashboard-home-intro {
  display: none;
  @media screen and (min-width: 360px) {
    display: flex;
  }
  background: url('/assets/images/home-background.jpg') no-repeat center bottom;
  background-size: cover;
  color: $white;
  z-index: 1;

  &::after {
    content: ''; // ::before and ::after both require content
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(0deg, #585858, #000000);
    opacity: 0.5;
  }

  > * {
    z-index: 2;
  }

  padding: $spacer;
  @include media-breakpoint-up(md) {
    padding: $spacer * 6;
  }
}

@keyframes revealing-bars {
  0% {
    transform: scaleY(0);
  }
  20% {
    transform: scaleY(1);
  }
}
