.consultant-ticket-single__consultant-name {
  font-size: 1.4rem;
  font-weight: 600;
}

.consultant-ticket-single__consultant-id {
  font-size: 1rem;
  font-weight: 400;
  color: $gray-600;
}

.consultant-ticket-single__date {
  font-size: 1rem;
  font-weight: 400;
}

.consultant-ticket-single_statuses {
  font-size: 1rem;
}
