.mediaccounts-clinic-date-component {
  @media screen and (max-width: 359.98px) {
    .form-label {
      display: none;
    }
    .react-datepicker-wrapper {
      display: block;
    }
  }

  .react-datepicker__input-container .form-control {
    cursor: pointer;
  }
}

// List item
.date-with-morning-or-afternoon-selector {
  background-color: $white;
  padding: 0.25rem 0;
  border-radius: $border-radius;
  border: 1px solid $gray-300;
  text-align: center;
  margin-bottom: divide($spacer, 2);
}

.date-with-morning-or-afternoon-title {
  font-weight: bold;
  display: block;
  padding-bottom: 5px;
  padding: 0 0.5rem 5px;
  border-bottom: 1px solid #cdcdcd;
  text-align: center;
}

.date-with-morning-or-afternoon-button-group {
  margin: 0.5rem 0 0.25rem;
}

.date-with-morning-or-afternoon-delete-all-button {
  margin-bottom: 25px;
  margin-left: 10px;
}
