.account-chooser-card {
  width: 360px;
  margin: 50px auto;
  @include media-breakpoint-up(md) {
    width: 450px;
  }

  .list-group-item {
    font-size: 1.1rem;
  }

  .list-group-item.active {
    background-color: $gray-300;
    color: $body-color;
  }
}
