.ReactModalPortal {
  position: absolute;
  z-index: 20;
}
.ReactModal__Overlay--after-open {
  background-color: rgba(0, 0, 0, 0.65) !important;
}

.ReactModal__Body--open {
  overflow: hidden;
}
.invoice-modal-seniorstaff-form-column {
  @include media-breakpoint-up(md) {
    border-left: 1px solid $gray-200;
  }
}

.modalised-overflowing-card-body {
  max-height: 80vh;
  overflow: auto;
}

.modal-confirmation-input {
  display: block;
  padding: 1rem;
  background-color: #fffadc;
}
