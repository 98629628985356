@import '../global/bootstrap-colors-overrides.scss';

.navbar {
  width: 100%;
  position: fixed;
  z-index: 10;
}

.consultant-account-highlight {
  background-color: $white;
  color: $medi-red;
  padding: 1px 4px;
}
