@media (min-width: 1095px) {
  .bulk-assign-form {
    top: 61px;
  }
}

@media (max-width: 1094px) {
  .bulk-assign-form {
    top: 96px;
  }
}

@media (max-width: 995px) {
  .bulk-assign-form {
    top: -60px;
  }
}

.bulk-assign-form {
  position: sticky;
  background-color: white;
  z-index: 2;
}
