.card {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
}
.embedded-cards {
  .card {
    box-shadow: none !important;
  }
}

.card .card-header.card-header--small-text {
  font-size: 0.9rem;
}

.card .card-header a {
  color: $body-color;

  &:hover {
    text-decoration: none;
  }
}

.card .card-body {
  padding: 0.5rem;
  @media screen and (min-width: 360px) {
    padding: 1.25rem;
  }
}

.card .card-header-tabs {
  margin-left: 0;
  margin-bottom: 0;
}

.card-tight {
  .card-header,
  .card-body {
    padding: 0.4rem 0.8rem;
  }
}
