.mediaccounts-faq-accordion {
}

.mediaccounts-faq-accordion__question {
  cursor: pointer;
  padding: 10px;
  border: 1px solid $gray-200;
  border-radius: 5px;

  &:hover {
    background-color: $gray-200;
  }
}

.mediaccounts-faq-accordion__answer {
  padding: $spacer;
}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
