// Override default variables before the import
//$body-bg: #000;
// Import Bootstrap and its default variables
@import './global/app-variables.scss';
@import './global/bootstrap-colors-overrides.scss';
@import './global/bootstrap-variable-overrides.scss';
@import '~bootstrap/scss/bootstrap.scss';
@import './global/default-components.scss';
@import '~@fortawesome/fontawesome-free/css/all.min.css';
@import './global/transitions.scss';

@import '~react-bootstrap-typeahead/css/Typeahead.scss';

/* Layout */
@import './components/layout-app-minimal.scss';

/* Components */
@import './components/badges.scss';
@import './components/bulk-assign-form.scss';
@import './components/typeahead.scss';
@import './components/cms-content.scss';
@import './components/twofactor.scss';
@import './components/header.scss';
@import './components/sidebar.scss';
@import './components/filters.scss';
@import './components/cards.scss';
@import './components/toasts.scss';
@import './components/tabular-data.scss';
@import './components/file-uploader.scss';
@import 'components/clinic-date-picker.scss';
@import 'components/account-chooser.scss';
@import './components/terms-page.scss';
@import './components/support-page.scss';
@import './components/patient-invoice.scss';

/* Dashboards */
@import './components/dashboards.scss';

/* Single Batch Invoice Screen */
@import './components/invoice-batch-single.scss';

/* Single Batch Invoice Screen */
@import './components/invoice-single.scss';

/* Single Consultant Ticket Screen */
@import './components/consultant-ticket-single.scss';

/* Create / Edit Screens */
@import './components/crud.scss';
@import './components/reports.scss';
@import './components/consultant-ccsd-codes.scss';
@import './components/consultant-onboarding.scss';

/* Billing Checklist */
@import './components/billing-checklist.scss';

/* Content Pages */
@import './components/content-pages.scss';

@import './components/work-schedule.scss';

@import './components/chaser-work-schedule.scss';
@import './components/invoice-modal';

@import './components/search';

.btn-xs {
  @include button-size($btn-padding-y-xs, $btn-padding-x-xs, $btn-font-size-xs, $btn-line-height-xs, $btn-border-radius-xs);
}

// temporary override
.react-datepicker-wrapper {
  display: inline-block !important;
  padding: 0 !important;
  border: 0 !important;
}

.snippable {
  .hoverable-line {
    display: inline-block;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }

  .hoverable-line:hover {
    background-color: #ff0000; /* Change this to your desired hover color */
  }
}
