@import '../global/bootstrap-colors-overrides.scss';

.tabular-data-actions {
  display: flex;
  justify-content: space-between;
}

.tabular-data-result-count {
  font-weight: normal;
  text-transform: none;
  font-size: 0.75rem;
  align-self: center;
}

.card {
  .table-row--urgent {
    border-left: 8px solid $warning !important;
  }

  .table-row--problem {
    border-left: 8px solid $medi-red !important;
  }

  .table-row--selected {
    border-left: 8px solid $green !important;
    background-color: rgba(51, 139, 43, 0.1) !important;
  }

  .table-row--cancelled {
    td {
      opacity: 0.25;
      text-decoration: line-through;
    }
  }
  .table-row--writeoff {
    background-color: rgba(135, 27, 31, 0.33) !important;
  }
  .table-row--void {
    background-color: rgba(76,0,97,.2) !important;
    color: $gray-700 !important;
  }
  .table-row--completed {
    border-left: 8px solid $primary !important;
  }
  .table-row--paid {
    border-left: 8px solid rgba(51, 139, 43, 0.5) !important;
    background-color: rgba(51, 139, 43, 0.05) !important;
  }
}

table.invoice-batch-list-table {
  margin-bottom: 0;

  &.dim-table {
    color: #cfcfcf !important;
    .text-body,
    .text-danger,
    .text-primary,
    .text-success,
    .status--completed,
    .badge,
    .text-warning,
    .text-secondary {
      color: #cfcfcf !important;
    }
    .badge,
    .btn {
      background-color: #cfcfcf !important;
      border-color: #cfcfcf !important;
    }
    .table-row--urgent,
    .table-row--completed,
    .table-row--problem {
      border-color: #cfcfcf !important;
    }
  }

  td:not(.has-notes) .note-count-wrapper {
    display: none;
  }

  .accept-button {
    display: inline-block;
    width: auto;
    // Apply Smallening Classes
    padding: 0.25rem 0.5rem;
    font-size: 0.74375rem;
    line-height: 1.5;
    border-radius: 0.2rem;

    .fa {
      display: none;
    }

    span {
      display: none;
    }
  }

  .accept-button ~ .btn {
    display: none;
  }

  .status--completed {
    color: $primary;
    font-weight: bold;
  }

  ul.hospitals {
    max-width: 150px;

    li {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}

table.user-list-table {
  margin-bottom: 0;

  td:not(.has-invoices) .invoice-count-wrapper {
    display: none;
  }
}
