@import 'bootstrap-colors-overrides';
@import 'app-variables';

// Global Bootstrap Overrides
$body-bg: $gray-200;

// Global Fonts
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&display=swap');
$font-family-base: 'Open Sans', sans-serif;
$headings-font-weight: 600;
$font-weight-bold: 600;
$font-size-base: 0.85rem !default;
$link-hover-decoration: none !default;
// Global Buttons
$btn-font-weight: 600;
.btn {
  text-transform: uppercase;

  .table & {
    margin-bottom: 0.15rem;
  }
}

// Global Tables
$table-hover-bg: rgba(246, 241, 210, 0.5);
$table-cell-padding: 0.65rem;

$list-inline-padding: 1rem !default;

$nav-tabs-link-active-bg: $gray-100 !default;
$nav-tabs-link-active-border-color: $gray-300 $gray-300 $nav-tabs-link-active-bg !default;

$font-size-xs: $font-size-base * 0.625 !default;
$line-height-xs: 1.5 !default;
$input-btn-padding-y-xs: 0.25rem !default;
$input-btn-padding-x-xs: 0.5rem !default;
$input-btn-font-size-xs: $font-size-xs !default;
$input-btn-line-height-xs: $line-height-xs !default;
$border-radius-xs: 0.175rem !default;

$btn-padding-y-xs: $input-btn-padding-y-xs !default;
$btn-padding-x-xs: $input-btn-padding-x-xs !default;
$btn-font-size-xs: $input-btn-font-size-xs !default;
$btn-line-height-xs: $input-btn-line-height-xs !default;
$btn-border-radius-xs: $border-radius-xs !default;

$nav-pills-link-active-color: $white !default;
$nav-pills-link-active-bg: $gray-600 !default;
