.card .card-header {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 1.2rem;
}
.card h2.card-header {
  text-transform: inherit !important;
}

.form-inline.form-fields-have-inline-block-label {
  label {
    width: 100%;
    justify-content: flex-start;
    margin-bottom: divide($spacer, 2);
  }
}

.btn.btn-sm-block {
  display: block !important;
  @include media-breakpoint-up(sm) {
    display: inline-block;
  }
}

.btn.btn-md-block {
  display: block !important;
  @include media-breakpoint-up(md) {
    display: inline-block;
  }
}

.spinner-wrapper {
  text-align: center;
}

.action-icon {
  cursor: pointer;
}

.hint-text {
  font-size: 0.8rem;
  font-style: italic;
}

.form-group small.form-text {
  font-size: 100%;

  &.text-danger {
    font-weight: 700;
    border-left: 5px solid #871b1f;
    padding-left: divide($spacer, 2);
    margin: $spacer 0;
  }
}

.react-datepicker-popper {
  z-index: 5 !important;
}

// Bootstrap responsive scrollable table component, isn't on iOS
.table-responsive {
  table {
    white-space: nowrap;

    thead,
    tfoot,
    tbody {
      white-space: initial;
    }
  }
}

.nav-pills {
  font-size: 0.9rem;
  text-transform: uppercase;
  font-weight: 600;
}

legend {
  font-size: 0.8rem;
  font-weight: 600;
}

.no-shadow {
  box-shadow: none !important;
}

.pointer {
  cursor: pointer;
}

.w-40 {
  width: 40%;
}
