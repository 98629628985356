//.patient-invoice {
//  width: 360px;
//  margin: 50px auto;
//  text-align: center;
//  @include media-breakpoint-up(md) {
//    width: 640px;
//  }
//}
//
//.patient-invoice-details {
//  width: 360px;
//  margin: 50px auto;
//  text-align: center;
//  @include media-breakpoint-up(md) {
//    width: 640px;
//  }
//}
//
//.billing-details {
//  .card-header {
//    text-align: center;
//  }
//  width: 360px;
//  margin: 50px auto;
//  text-align: left;
//  @include media-breakpoint-up(md) {
//    width: 640px;
//  }
//}
//
//.balance-paid {
//  color: green;
//}

.worldpay-iframe-card {
  width: 100%;
  margin: 50px auto;
  text-align: center;
  //@include media-breakpoint-up(md) {
  //  width: 100%;
  //}
}

//.invoice-table {
//  margin: 20px auto;
//  display: flex;
//  place-content: space-around;
//}
