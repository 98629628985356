.app-minimal {
  .main-content {
    padding-left: 0 !important;
  }

  .login-card {
    width: 360px;
    margin: 50px auto;
    @include media-breakpoint-up(md) {
      width: 640px;
    }
  }
  .login-help {
    text-decoration: underline;
    a {
      color: black;
    }
  }

  .qr-code-wrapper {
    max-width: 200px;
    border: 1px solid $gray-200;
    margin: 0 auto;

    img {
      max-width: 100%;
      height: auto;
    }
  }
}

.app-spinner-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: grid;
  place-items: center;
}
