.search-results {
  position: absolute;
  background: white;
  width: 70%;

  .results-row {
    max-height: 80vh;
  }

  ul {
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    max-height: 400px;
    list-style-type: none;
    padding-left: 0;
  }
  ul,
  li {
    list-style-type: none;
    padding-left: 0;
  }
  a:hover {
    text-decoration: underline;
  }
}
