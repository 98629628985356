.terms-conditions-card {
  .termsSection {
    font-size: 0.85rem;
    width: 90%;
    margin: 20px 5% 40px 5%;
    border: 1px solid $gray-400;
    padding: 10px;

    table {
      margin: 10px 0 10px 22px;

      tr > td:first-child {
        font-weight: bold;
        padding-right: 20px;
        width: 260px;
      }

      tr td {
        padding-bottom: 10px;
      }
    }

    ol:first-of-type {
      li {
        &:first-child {
          margin-top: 10px;
        }

        margin-bottom: 10px;
      }
    }

    ol {
      counter-reset: item;
    }

    li {
      display: block;

      li:before {
        font-weight: normal;
      }
    }

    li:before {
      font-weight: bold;
      content: counters(item, '.') ' ';
      counter-increment: item;
    }
  }

  .terms-welcome {
    font-size: 1.25rem;
    @include media-breakpoint-up(md) {
      font-size: 1.75rem;
    }

    font-weight: normal;
    margin: 2rem 0;
  }

  .terms-approval-panel {
    background-color: white;
    padding: $spacer * 2 $spacer;
    border: 1px solid $gray-400;
    width: 90%;
    margin: 0 auto;
    text-align: left;
    @include media-breakpoint-up(md) {
      padding: $spacer * 2 100px;
    }
  }
}
