.card .card-header.card-header--invoice-batch-single {
  font-size: 0.9rem;
  text-transform: none;
  font-weight: 400;
  font-size: 0.8rem;
}

.invoice-batch-single__statuses {
  font-size: 1rem;
}

.invoice-batch-single__id {
  //border-left: 1px solid rgba(0,0,0,0.15);
  //margin-left: 7px;
  //padding-left: 10px;
}

.invoice-batch-single__date {
}

.invoice-batch-single__author {
}

.invoice-batch-single__consultant-name {
  font-size: 1.4rem;
  font-weight: 600;
}

.invoice-batch-single__consultant-id {
  font-size: 1rem;
  font-weight: 400;
  color: $gray-600;
}

.invoice-batch-single__date {
  font-size: 1rem;
  font-weight: 400;
}

.invoice-batch-single__section {
  position: relative;
  background-color: $gray-100;
  padding: $spacer;
  border: 1px solid rgba(0, 0, 0, 0.05);

  .invoice-batch-single__files-area__originals {
    flex-basis: 50%;
  }

  .invoice-batch-single__files-area__checked {
    flex-basis: 50%;
  }

  .btn {
    width: 100%;
    margin-bottom: divide($spacer, 2);
  }

  .potential-duplicates .btn {
    width: auto;
  }
}

.invoice-batch-single__section__title {
  font-size: 0.8rem;
  font-weight: 500;
  text-transform: uppercase;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  padding-bottom: divide($spacer, 2);
  margin-bottom: $spacer;
}

.invoice-batch-single__section-icon {
  width: 40px;
  height: 40px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 1000px;
  position: absolute;
  left: -15px;
  top: 7px;
  font-size: 1.3rem;
  color: $gray-700;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.invoice-batch-single__section-header {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: $spacer;
  padding-left: 20px;
}

.invoice-batch-single__section-header__invoices-supplied-wrap {
  margin-left: auto;
  margin-right: $spacer;
}

.invoice-batch-single__notes-wrapper {
}

.invoice-batch-single__note {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: $spacer;
  padding-bottom: divide($spacer, 2);

  &:last-of-type {
    border-bottom: 0 none transparent;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

.invoice-batch-single__note-heading {
  margin: divide($spacer, 2) 0;
  font-size: 0.9rem;
}

.invoice-batch-single__note-content {
  margin-bottom: divide($spacer, 2);
  font-size: 0.8rem;
}

.invoice-batch-single__associated-numbers-actions {
}

.audit-log-clamp li {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.actions-button-wrap {
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
  margin: 0 (-$spacer);
  padding-bottom: $spacer;

  .btn {
    @include media-breakpoint-up(medium) {
      margin: $spacer;
    }
    margin: $spacer $spacer 0;
  }
}

.invoice-number-editor-badge {
  cursor: pointer;
}

.number-invoices-edit-form {
  label {
    display: none;
  }

  .form-group {
    margin-bottom: 0;
  }
}

.save-controls {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  padding-bottom: 1rem;
}
