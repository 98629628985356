.nice-image-upload {
  border: 2px dashed lightgray;
  border-radius: 6px;
  text-align: center;

  .form-control-file {
    width: 100%;
    height: 100%;
    visibility: hidden;
    cursor: pointer;
    position: relative;
    min-height: 6em;
  }

  .form-control-file:before {
    content: attr(data-title);
    position: absolute;
    visibility: visible;
    width: 100%;
    text-align: center;
    overflow: hidden;
    color: #6c757d;
    font-size: 11px;
    @media screen and (min-width: 360px) {
      font-size: 1em;
    }
    min-height: 6em;
    line-height: 2em;
    padding-top: 2em;
  }
}

.show-only-ie-and-edge-non-chromium {
  display: none;
}

@supports (-ms-ime-align: auto) {
  .nice-image-upload {
    background: url('/assets/images/np_plus_2178969_000000.svg') no-repeat center center;
    background-size: 5%;
  }
  .form-control-file {
    visibility: visible !important;
    opacity: 0;
  }
  .show-only-ie-and-edge-non-chromium {
    display: block;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .nice-image-upload {
    background: url('/assets/images/np_plus_2178969_000000.svg') no-repeat center center;
    background-size: 5%;
  }
  .form-control-file {
    visibility: visible !important;
    opacity: 0;
  }
  .show-only-ie-and-edge-non-chromium {
    display: block;
  }
}

.mediaccounts-file-list {
  position: relative;
  background-color: #f8f9fa;
  padding: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.file-browser-list {
  .file-browser-list-item {
    padding: divide($spacer, 2) $spacer;
    border: 1px solid $gray-200;
    background-color: $white;

    p {
      display: flex;
      justify-content: space-between;
    }
  }
}
