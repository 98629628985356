.mediaaccounts-2fa-explainer {
  text-align: center;

  p {
    font-size: 1.1rem;
  }

  .step-label {
    padding: 2px 5px;
    background-color: $medi-red;
    color: white;
    text-transform: uppercase;
    font-weight: $font-weight-bold;
    margin-bottom: 20px;
    display: inline-block;
    font-size: 13px;
  }

  hr {
    margin: 1.75rem 0;
  }

  .appstore-links {
    text-align: center;

    a {
      margin: 0 10px;
    }

    img {
      max-height: 45px;
    }
  }
}

.twofactor-skipping {
  text-align: center;
  background: $medi-2fa-warning;
  padding: 0.5rem;
  border: 1px solid darken($medi-2fa-warning, 10%);
  border-radius: 5px;
  margin-bottom: 1rem;
  font-size: 0.85rem !important;
}
