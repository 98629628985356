.main {
  position: relative;
}

.mediaccount-toastwrapper {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 100;
}

.medi-toast {
  border: 2px solid transparent;
}

.toast {
  font-size: 1rem;
  //box-shadow: 0 5px 50px rgba(0, 0, 0, 0.5), 0 2px 10px rgba(0, 0, 0, 0.1);

  .toast-body {
    background-color: $white;
    color: $body-color;

    &.positive {
      border: none !important;
      color: $white;
      background-color: $green;
    }

    &.negative {
      border: none !important;
      color: $white;
      background-color: $danger;
    }
  }
}
