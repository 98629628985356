.crud__section .consultant-ccsd-table-code-cell {
  max-width: 200px;
  overflow: hidden;
  white-space: pre-wrap;
}

// Edit only

.batch-invoice-ccsd-table-title-cell {
  min-width: 150px;
  text-align: center;
  font-size: 0.75rem;
  max-width: 150px;
  padding: 0.65rem 4px !important;
}

@include media-breakpoint-up(md) {
  .default-ccsd-table tr td + td {
    width: 300px;
  }
}

.isOther {
  background-color: $gray-200 !important;
  font-style: italic;
}

// Batch invoice screen
.mediaccounts-batch-invoice-ccsd-table thead th {
  position: sticky;
  background-color: white;
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.1);
  $navbarheight: 61px;
  $approxtablerowheight: 40px;

  &.stuck-row-1 {
    top: $navbarheight;
  }

  &.stuck-row-2 {
    top: $navbarheight + 50px;
  }

  &.stuck-row-3 {
    top: $navbarheight + 85px;
    background-color: $gray-100;
  }
}

// Edit Consultant Screen
.consultant-ccsd-table {
  background-color: white;

  tr td,
  tr th {
    background-color: white;
  }

  .consultant-ccsd-table-code-cell {
    min-width: 300px;
    max-width: 300px;
  }

  .stuck-row {
    position: sticky;
    background-color: $gray-100;
    box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.1);
    $navbarheight: 61px;
    $approxtablerowheight: 40px;

    &.stuck-row-1 {
      top: $navbarheight;
    }

    &.stuck-row-2 {
      top: $navbarheight + 50px;
    }

    &.stuck-row-3 {
      top: $navbarheight + 85px;
    }
  }

  .consultant-ccsd-table-title-cell {
    min-width: 120px;
    text-align: center;
    font-size: 0.75rem;
    max-width: 120px;
    padding: 0.65rem 4px !important;
  }
}

.batch-invoice-ccsd-code-title-column {
  max-width: 300px;
  overflow: hidden;
}

.batch-invoice-ccsd-code-insurer-column {
  min-width: 120px;
  max-width: 120px;
  text-align: center;
}
