.card .card-header.card-header--crud {
  font-size: 0.9rem;
  text-transform: none;
  font-weight: 400;
  font-size: 0.8rem;
}

.crud__statuses {
  font-size: 1rem;
}

.crud__id {
  border-left: 1px solid rgba(0, 0, 0, 0.15);
  margin-left: 7px;
  padding-left: 10px;
}

.crud__body-title {
  font-size: 1.4rem;
  font-weight: 600;
  margin-bottom: 0;
}

.crud__body-title__append {
  font-size: 1rem;
  font-weight: 400;
  color: $gray-600;
}

.crud__section {
  position: relative;
  background-color: $gray-100;
  padding: $spacer divide($spacer, 2);
  @media screen and (min-width: 360px) {
    padding: $spacer;
  }
  border: 1px solid rgba(0, 0, 0, 0.05);

  .crud__files-area__originals {
    flex-basis: 50%;
  }

  .crud__files-area__checked {
    flex-basis: 50%;
  }
}

.crud__section__title {
  font-size: 0.8rem;
  font-weight: 500;
  text-transform: uppercase;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  padding-bottom: divide($spacer, 2);
  margin-bottom: $spacer;
}

.crud__section-icon {
  width: 40px;
  height: 40px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 1000px;
  position: absolute;
  left: -15px;
  top: 7px;
  font-size: 1.3rem;
  color: $gray-700;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.crud__section-header {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: $spacer;
  padding-left: 20px;
}

.crud__section-subheader {
  font-size: 0.8rem;
  font-weight: 400;
  margin-bottom: $spacer * 2;
  padding-left: 20px;
  border-bottom: 1px solid $gray-300;
  padding-bottom: $spacer;
}

.crud__section__assocated-user__checkboxes {
  & > div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .checkbox {
      margin-right: $spacer;
    }
  }

  input[type='checkbox'],
  label {
    margin-bottom: divide($spacer, 2);
  }
}

.crud__section__assocated-user {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: $spacer;
}

.crud__section__assocated-user--create {
  background-color: $white;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.sub-account-invite-form {
}
