.mediaccounts-billing-checklist-table {
  .day {
    display: block;
    text-align: center;
    color: $gray-400;
  }

  .date {
    display: block;
    text-align: center;
    font-weight: bold;
  }

  .highlight-today {
    background-color: $medi-today-table-highlight;

    .day {
      color: $danger;
      font-weight: bold;
    }

    .date {
      color: $danger;
    }
  }

  .status {
    text-align: center;
    font-size: 1rem;
    .fa {
      margin: 2px;
    }
    i.fa-times-circle {
      color: $gray-400;
    }

    i.fa-check-circle {
      color: $primary;
    }
  }

  /* Sticky header */
  thead th {
    position: sticky;
    top: 114px;
    background-color: white;
    box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.1);
  }

  &.invoice-batch-fees-table {
    margin-top: $spacer;

    thead th {
      position: relative;
      top: 0;
    }
  }
}

.mediaccounts-billing-checklist__page-title {
  display: inline-block;
  @include media-breakpoint-up(sm) {
    display: none;
  }
  @include media-breakpoint-up(lg) {
    display: inline-block;
  }
}

.mediaccounts-billing-checklist__week-title {
  font-size: 1rem;
  align-self: center;
}

.mediaccounts-billing-checklist__card {
  position: relative;

  .card-header {
    position: sticky;
    top: 60px;
    background-color: $gray-200;
  }
}

.mediaccounts-work-schedule-totals {
  background-color: $gray-100;
}
