.report-confirmation-summary-wrapper {
  padding: $spacer;
  background-color: white;
}

.table--reports-preview {
  font-size: 0.75rem;
}

.table-max-height--table--reports-preview {
  overflow-y: scroll;
  max-height: 500px;
  margin-bottom: $spacer;
}
