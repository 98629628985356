.mediaccount-filter-wrapper {
  padding: 0.5rem;
  background-color: $gray-200;
}

.form-data-filters {
  &.form-inline,
  &.fake-inline {
    .form-group {
      margin-right: $spacer * 1.25;
      margin-bottom: divide($spacer, 2);

      .form-label {
        margin-right: divide($spacer, 2);
        font-size: 10px;
        text-transform: uppercase;
        font-weight: 600;
      }

      .form-control {
        // Make smaller
        padding: 0.25rem 0.5rem;
        font-size: 0.75rem;
        line-height: 1.25;
        border-radius: 0.2rem;
        height: auto;
      }
    }
  }
  &.fake-inline {
    .form-group {
      margin-bottom: divide($spacer, 4);
      .form-label {
        margin-bottom: divide($spacer, 8);
      }
    }
  }

  .filter-actions {
    //min-width: 180px;
    //margin-left: auto;
    //text-align: right;
    margin-bottom: divide($spacer, 2);

    .btn {
    }
  }
}
